
import { getFollowUpResult } from "@/api";
import { handleSearch } from "@/util/tool";
import { Options, Vue } from "vue-class-component";

@Options({})
export default class TypeOne extends Vue {
  title: string = "结果";
  membersData: any = {};
  noVisit = 0;
  sexMap = {
    0: "男",
    1: "女",
    2: "未知"
  };
  handleData(data: Array<any>, target: boolean) {
    let storeData: Array<{ name: string; checkecd: boolean }> = [];

    if (data) {
      if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].checked === target) {
            storeData.push(data[i]);
          }
        }
      } else {
        storeData.push({ name: "", checkecd: false });
      }
    }
    return storeData;
  }
  get habbitContent() {
    return (
      (this.membersData.habbitContent &&
        this.membersData.habbitContent.content &&
        this.handleData(this.membersData.habbitContent.content, true)) ||
      []
    );
  }
  get diseaseContent() {
    return (
      (this.membersData.diseaseContent &&
        this.membersData.diseaseContent.content &&
        this.handleData(this.membersData.diseaseContent.content, true)) ||
      []
    );
  }
  get homoContent() {
    return (
      (this.membersData.homoContent &&
        this.membersData.homoContent.content &&
        this.handleData(this.membersData.homoContent.content, true)) ||
      []
    );
  }
  created() {
    const search = window.location.search;

    const searchFull = handleSearch(search);

    if (searchFull.token) {
      localStorage.setItem("userToken", searchFull.token);
    }

    this.init(searchFull.fuId);
  }
  async init(id: string | number) {
    try {
      const result = await getFollowUpResult(id);
      if (result.code === 200) {
        this.membersData = JSON.parse(result.data.fuContent);
        this.noVisit = result.data.noVisit;

        console.log(this.membersData)

        if (this.noVisit === 0) {
          document.title = "患者情况";
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
}
