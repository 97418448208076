
import { Options, Vue } from "vue-class-component";
import TypeOne from "./view/typeOne/index.vue";
import TypeTwo from "./view/typeTwo/index.vue";
import TypeThree from "./view/typeThree/index.vue";
import TypeFour from "./view/typeFour/index.vue";
import TypeEleven from "./view/typeEleven/index.vue";

@Options({
  components: {
    TypeOne,
    TypeTwo,
    TypeThree,
    TypeFour,
    TypeEleven
  }
})
export default class Dignose extends Vue {
  title: string = "结果";
}
