
import { getFollowUpResult } from "@/api";
import { handleSearch } from "@/util/tool";
import { Options, Vue } from "vue-class-component";

@Options({})
export default class TypeOne extends Vue {
  title: string = "结果";
  membersData: any = {};
  noVisit = 0;
  sexMap = {
    0: "男",
    1: "女",
    2: "未知"
  };
  handleData(data: Array<any>, target: boolean) {
    let storeData: Array<{ name: string; checkecd: boolean }> = [];

    if (data) {
      if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].checked === target) {
            storeData.push(data[i]);
          }
        }
      } else {
        storeData.push({ name: "", checkecd: false });
      }
    }
    return storeData;
  }
  needHide(target: string) {
    if (Array.isArray(this.glpInfo)) {
      for (let i = 0; i < this.glpInfo.length; i++) {
        if (this.glpInfo[i].name === target) {
          return true;
        }
      }
    }

    return false;
  }
  get questions() {
    return this.handleData(this.membersData.questions, true);
  }
  get glpOrigin() {
    return typeof this.membersData.glp === "string";
  }
  get glpInfo() {
    return this.glpOrigin
      ? this.membersData.glp
      : (this.membersData &&
        this.membersData.glp &&
        this.membersData.glp["glpInfo"] &&
        this.handleData(this.membersData.glp["glpInfo"], true)) ||
      [];
  }
  get ways() {
    return this.handleData(this.membersData.ways, true);
  }
  get hopeways() {
    return this.handleData(this.membersData.hopeways, true);
  }
  get homeSugarContent() {
    return (
      (this.membersData.homeSugarContent &&
        this.membersData.homeSugarContent.content &&
        this.handleData(this.membersData.homeSugarContent.content, true)) ||
      []
    );
  }
  get homePressureContent() {
    return (
      (this.membersData.homePressureContent &&
        this.membersData.homePressureContent.content &&
        this.handleData(this.membersData.homePressureContent.content, true)) ||
      []
    );
  }
  get homeFatContent() {
    return (
      (this.membersData.homeFatContent &&
        this.membersData.homeFatContent.content &&
        this.handleData(this.membersData.homeFatContent.content, true)) ||
      []
    );
  }
  get homeMouseContent() {
    return (
      (this.membersData.homeMouseContent &&
        this.membersData.homeMouseContent.content &&
        this.handleData(this.membersData.homeMouseContent.content, true)) ||
      []
    );
  }
  get location() {
    return this.handleData(this.membersData.location, true);
  }
  get marriagesInfo() {
    let marriages = this.membersData.marriageInfo && this.membersData.marriageInfo.marriages;
    return this.handleData(marriages, true);
  }
  get marriageInfo() {
    let marriage = this.membersData.marriageInfo && this.membersData.marriageInfo.marriage;
    return this.handleData(marriage, true);
  }
  get marriage() {
    return this.handleData(this.membersData.marriage, true);
  }
  get menstruation() {
    return this.handleData(this.membersData.menstruation, true);
  }
  get diabetes() {
    return this.handleData(this.membersData.diabetes, true);
  }
  get pressureMedcine() {
    return (
      (this.membersData.pressure &&
        this.membersData.pressure["medcine"] &&
        this.handleData(this.membersData.pressure["medcine"], true)) ||
      []
    );
  }
  get asMedcine() {
    return (
      (this.membersData.asMedcine &&
        this.membersData.asMedcine["medcine"] &&
        this.handleData(this.membersData.asMedcine["medcine"], true)) ||
      []
    );
  }
  get boliMedcine() {
    return (
      (this.membersData.boliMedcine &&
        this.membersData.boliMedcine["medcine"] &&
        this.handleData(this.membersData.boliMedcine["medcine"], true)) ||
      []
    );
  }
  get vacationDetail() {
    return (this.membersData.vacationMedcine &&
      this.membersData.vacationMedcine["vacationDetail"]
    );
  }
  get vacationMedcine() {
    return (
      (this.membersData.vacationMedcine &&
        this.membersData.vacationMedcine["medcine"] &&
        this.handleData(this.membersData.vacationMedcine["medcine"], true)) ||
      []
    );
  }
  get cigreatMedcine() {
    return (
      (this.membersData.cigreatMedcine &&
        this.membersData.cigreatMedcine["medcine"] &&
        this.handleData(this.membersData.cigreatMedcine["medcine"], true)) ||
      []
    );
  }
  get sugarMedcine() {
    return (
      (this.membersData.sugarMedcine &&
        this.membersData.sugarMedcine["medcine"] &&
        this.handleData(this.membersData.sugarMedcine["medcine"], true)) ||
      []
    );
  }
  get insulinMedcine() {
    return (
      (this.membersData.insulinMedcine &&
        this.membersData.insulinMedcine["medcine"] &&
        this.handleData(this.membersData.insulinMedcine["medcine"], true)) ||
      []
    );
  }
  get coronaryMedcine() {
    return (
      (this.membersData.coronaryMedcine &&
        this.membersData.coronaryMedcine["medcine"] &&
        this.handleData(this.membersData.coronaryMedcine["medcine"], true)) ||
      []
    );
  }
  get braceMedcine() {
    return (
      (this.membersData.braceMedcine &&
        this.membersData.braceMedcine["medcine"] &&
        this.handleData(this.membersData.braceMedcine["medcine"], true)) ||
      []
    );
  }
  get insulinMedcineDetail() {
    return (this.membersData.insulinMedcine && this.membersData.insulinMedcine["medcineDetail"]) || "";
  }
  get insulinMedcineDetail2() {
    return (this.membersData.insulinMedcine && this.membersData.insulinMedcine["medcineDetail2"]) || "";
  }
  get coronaryMedcineDetail() {
    return (this.membersData.coronaryMedcine && this.membersData.coronaryMedcine["medcineDetail"]) || "";
  }
  get braceMedcineDetail() {
    return (this.membersData.braceMedcine && this.membersData.braceMedcine["medcineDetail"]) || "";
  }
  get braceMedcineDetail2() {
    return (this.membersData.braceMedcine && this.membersData.braceMedcine["medcineDetail2"]) || "";
  }
  get pressureMedcineDetail() {
    return (this.membersData.pressure && this.membersData.pressure["medcineDetail"]) || "";
  }
  get sugarMedcineDetail() {
    return (this.membersData.sugarMedcine && this.membersData.sugarMedcine["medcineDetail"]) || "";
  }
  created() {
    const search = window.location.search;

    const searchFull = handleSearch(search);

    if (searchFull.token) {
      localStorage.setItem("userToken", searchFull.token);
    }

    this.init(searchFull.fuId);
  }
  async init(id: string | number) {
    try {
      const result = await getFollowUpResult(id);
      if (result.code === 200) {
        this.membersData = JSON.parse(result.data.fuContent);
        this.noVisit = result.data.noVisit;

        console.log(this.membersData)

        if (this.noVisit === 0) {
          document.title = "患者情况";
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
}
