
import {Options, Vue} from "vue-class-component";
// import {parseUrl} from "@/util/tool";

@Options({
  components: {}
})
export default class Home extends Vue {
  title: string = "inBody";
  compositionShow = false;
  muscleShow = false;
  fatShow = false;
  weightShow = false;
  balancedShow = false;
  created() {
    
  }
  compositionFn(index: number, param: boolean) {
    console.log("index:" + index)
    switch (index) {
      case 0:
        this.compositionShow = param;
        break;
      case 1:
        this.muscleShow = param;
        break;
      case 2:
        this.fatShow = param;
        break;
      case 3:
        this.weightShow = param;
        break;
      case 4:
        this.balancedShow = param;
        break;
    }
  }
  mounted() {}
}
