
import { getFollowUpResult } from "@/api";
import { Options, Vue } from "vue-class-component";
import { handleSearch } from "@/util/tool";

@Options({})
export default class TypeThree extends Vue {
  title: string = "结果";
  dataGroup: any = [];
  noVisit = 0;
  sexMap = {
    0: "男",
    1: "女",
    2: "未知"
  };
  created() {
    const search = window.location.search;

    const searchFull = handleSearch(search);

    if (searchFull.token) {
      localStorage.setItem("userToken", searchFull.token);
    }

    this.init(searchFull.fuId);
  }
  async init(id: string | number) {
    try {
      const result = await getFollowUpResult(id);
      if (result.code === 200) {
        this.dataGroup = JSON.parse(result.data.fuContent);
        this.noVisit = result.data.noVisit;

        if (this.noVisit === 2) {
          document.title = "术前宣教考核答卷";
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
}
