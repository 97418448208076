import { createActivityClient } from "@/gateway";

const activityClient = createActivityClient();

export async function getFollowUpResult(fuId: string | number) {
  const result = await activityClient<any>({
    url: `/mini/followup/${fuId}`,
    method: "GET"
  });

  if (JSON.parse(result.data).code === 200) {
    return Promise.resolve(JSON.parse(result.data));
  }

  return Promise.reject(JSON.parse(result.data));
}

export async function inBodyDetail(ibId: string) {
  const result = await activityClient<any>({
    url: `/mini/inbody/${ibId}`,
    method: "GET"
  });

  if (JSON.parse(result.data).code === 200) {
    return Promise.resolve(JSON.parse(result.data));
  }

  return Promise.reject(JSON.parse(result.data));
}

export async function datav2H(groupId: number | string) {
  const result = await activityClient<any>({
    url: `/datav/2h/list/${groupId}`,
    method: "GET"
  });

  if (JSON.parse(result.data).code === 200) {
    return Promise.resolve(JSON.parse(result.data));
  }

  return Promise.reject(JSON.parse(result.data));
}
