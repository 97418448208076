import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sheet-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_type_one = _resolveComponent("type-one")!
  const _component_type_two = _resolveComponent("type-two")!
  const _component_type_three = _resolveComponent("type-three")!
  const _component_type_four = _resolveComponent("type-four")!
  const _component_type_eleven = _resolveComponent("type-eleven")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_type_one),
    _createVNode(_component_type_two),
    _createVNode(_component_type_three),
    _createVNode(_component_type_four),
    _createVNode(_component_type_eleven)
  ]))
}