import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "check-sheet-box" }
const _hoisted_3 = { class: "user-edit" }
const _hoisted_4 = { class: "key" }
const _hoisted_5 = { class: "insert-block" }
const _hoisted_6 = {
  key: 0,
  class: "insert-content"
}
const _hoisted_7 = {
  key: 1,
  class: "insert-content"
}
const _hoisted_8 = { class: "user-edit" }
const _hoisted_9 = { class: "key" }
const _hoisted_10 = {
  key: 0,
  class: "insert-content"
}
const _hoisted_11 = {
  key: 1,
  class: "insert-content"
}
const _hoisted_12 = { class: "single" }
const _hoisted_13 = { class: "key" }
const _hoisted_14 = { class: "value flex-row flex-wrap" }
const _hoisted_15 = { class: "double" }
const _hoisted_16 = { class: "key" }
const _hoisted_17 = { class: "flex-row flex-wrap" }
const _hoisted_18 = { class: "flex-row flex-ai-center" }
const _hoisted_19 = {
  key: 0,
  class: "extra-block"
}
const _hoisted_20 = { class: "insert-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.dataGroup && _ctx.noVisit === 3)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataGroup, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "item",
              key: index
            }, [
              _withDirectives(_createElementVNode("view", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(index + 1) + "." + _toDisplayString(item.key), 1),
                _createElementVNode("div", _hoisted_5, [
                  (item.date !== '请选择')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(item.date), 1))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7, "未填写"))
                ])
              ], 512), [
                [_vShow, item.type === 'time']
              ]),
              _withDirectives(_createElementVNode("view", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, _toDisplayString(index + 1) + "." + _toDisplayString(item.key), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.details, (item1, index1) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "insert-block",
                    key: index1
                  }, [
                    (item1.text)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(item1.text), 1))
                      : (_openBlock(), _createElementBlock("div", _hoisted_11, "未填写"))
                  ]))
                }), 128))
              ], 512), [
                [_vShow, item.type === 'insert']
              ]),
              _withDirectives(_createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, _toDisplayString(index + 1) + "." + _toDisplayString(item.key), 1),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_14, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, (item1, index1) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "option flex-row flex-ai-center",
                        key: index1
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["item", { on1: item1.checked }])
                        }, null, 2),
                        _createElementVNode("div", {
                          class: _normalizeClass({ answer: item1.answer })
                        }, _toDisplayString(item1.name), 3)
                      ]))
                    }), 128))
                  ])
                ])
              ], 512), [
                [_vShow, item.type === 'single']
              ]),
              _withDirectives(_createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, _toDisplayString(index + 1) + "." + _toDisplayString(item.key), 1),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_17, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, (item1, index1) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "flex-row option",
                        key: index1
                      }, [
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["item", { on2: item1.checked }])
                          }, null, 2)
                        ]),
                        _createElementVNode("div", {
                          class: _normalizeClass({ answer: item1.answer })
                        }, _toDisplayString(item1.name), 3)
                      ]))
                    }), 128))
                  ]),
                  (item.extra && item.options[item.options.length - 1].checked === true)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.extra, (item1, index1) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "extra-wrap flex-row flex-ai-center",
                            key: index1
                          }, [
                            _createElementVNode("div", _hoisted_20, _toDisplayString(item1.text), 1)
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ], 512), [
                [_vShow, item.type === 'double']
              ])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}