import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "check-sheet-box" }
const _hoisted_3 = { class: "single" }
const _hoisted_4 = { class: "key" }
const _hoisted_5 = { class: "value flex-row flex-wrap" }
const _hoisted_6 = {
  key: 0,
  class: "mask"
}
const _hoisted_7 = { class: "double" }
const _hoisted_8 = { class: "key" }
const _hoisted_9 = { class: "flex-row flex-wrap" }
const _hoisted_10 = { class: "flex-row flex-ai-center" }
const _hoisted_11 = {
  key: 0,
  class: "mask"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.dataGroup && _ctx.noVisit === 2)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataGroup, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "item",
              key: index
            }, [
              _withDirectives(_createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(index + 1) + "." + _toDisplayString(item.key), 1),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, (item1, index1) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "option flex-row flex-ai-center",
                        key: index1
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["item", { on1: item1.checked }])
                        }, null, 2),
                        _createElementVNode("div", {
                          class: _normalizeClass({ answer: item1.answer && item.answerShow })
                        }, _toDisplayString(item1.name), 3)
                      ]))
                    }), 128))
                  ])
                ]),
                (item.answerShow)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                  : _createCommentVNode("", true)
              ], 512), [
                [_vShow, item.type === 'single' && item.show]
              ]),
              _withDirectives(_createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(index + 1) + "." + _toDisplayString(item.key), 1),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, (item1, index1) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "flex-row option",
                        key: index1
                      }, [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["item", { on2: item1.checked }])
                          }, null, 2)
                        ]),
                        _createElementVNode("div", {
                          class: _normalizeClass({ answer: item1.answer && item.answerShow })
                        }, _toDisplayString(item1.name), 3)
                      ]))
                    }), 128))
                  ])
                ]),
                (item.answerShow)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11))
                  : _createCommentVNode("", true)
              ], 512), [
                [_vShow, item.type === 'double' && item.show]
              ])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}