
import { getFollowUpResult } from "@/api";
import { Options, Vue } from "vue-class-component";
import { handleSearch } from "@/util/tool";

@Options({})
export default class TypeTwo extends Vue {
  title: string = "结果";
  membersData: any = {};
  resultHandleInfoList: Array<{ key: string; name: string; value: number }> = [];
  afterImgUrl: Array<string> = [];
  resultMultiList: any = [];
  noVisit = 0;
  sexMap = {
    0: "男",
    1: "女",
    2: "未知"
  };
  created() {
    const search = window.location.search;

    const searchFull = handleSearch(search);

    if (searchFull.token) {
      localStorage.setItem("userToken", searchFull.token);
    }

    this.init(searchFull.fuId);
  }
  async init(id: string | number) {
    try {
      const result = await getFollowUpResult(id);
      if (result.code === 200) {
        this.membersData = JSON.parse(result.data.fuContent);

        if (Number(id) < 3850) {
          Object.assign(this.membersData, {
            sex: result.data.sex
          })
        }
        this.noVisit = result.data.noVisit;

        if (this.noVisit === 1) {
          document.title = "中医症候积分";
        }

        this.resultHandleInfoList = this.handleInfoList(this.membersData.infoList);

        this.resultMultiList = this.membersData.multiList;
        this.afterImgUrl = this.membersData.afterImgUrl;
      }
    } catch (err) {
      console.log(err);
    }
  }
  get score() {
    let score = 0;
    if (this.resultHandleInfoList.length) {
      this.resultHandleInfoList.forEach((item) => {
        score += Number(item.value);
      });
    }
    return score;
  }
  // handleMuiltiList(data) {
  //   let arr: any = [];

  //   for (let i = 0; i < data.length; i++) {
  //     let subArr: any = [];
  //     for (let j = 0; j < data[i].options.length; j++) {
  //       if (data[i].options[j].checked === true) {
  //         subArr.push({
  //           name: data[i].options[j].name
  //         });
  //       }

  //       arr.push({ key: data[i].key, options: subArr });
  //     }

  //     console.log(arr);

  //     return arr;
  //   }
  // }
  handleInfoList(data) {
    console.log(data);
    let arr: any = [];
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].options.length; j++) {
        if (data[i].options[j].checked === true) {
          let rt: any = {
            key: data[i].key,
            value: data[i].options[j].value,
            name: data[i].options[j].name
          };

          if (j === data[i].options.length - 1) {
            if (data[i] && data[i].details) {
              rt.details = (data[i] && data[i].details) || [];
            }
          }
          arr.push(rt);

          break;
        }

        if (j === data[i].options.length - 1) {
          if (data[i].options[j].checked === false) {
            arr.push({
              key: data[i].key,
              value: 0,
              name: "未选择"
            });
          }
        }
      }
    }

    return arr;
  }
}
