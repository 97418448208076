import axios from "axios";
import { AxiosRequestConfig } from "axios";
import * as R from "ramda";
import Qs from "qs";
import { parseUrl } from "@/util/tool";

// meta类型扩展
interface IGatewayMetaType {
  auth?: boolean;
}

export interface IGatewayRequestConfig extends AxiosRequestConfig {
  url: string;
  meta?: IGatewayMetaType;
  contentType?: boolean;
}
export const createActivityClient = function (baseUrl?: string) {
  const isTest = parseUrl().debug;
  if (!baseUrl) {
    // baseUrl = "https://platform.lianxin.aijk.net/stage-api";
    baseUrl = isTest ? "https://saas.lianxinjiankang.com/stage-api" : "https://saas.lianxinjiankang.com/stage-api";
  }
  const activityClient = getInstance({
    baseURL: baseUrl
  });
  return activityClient;
};

export default function getInstance(config: any) {
  const { baseURL, timeout } = config;
  const instance = axios.create({
    baseURL: baseURL,
    timeout: timeout,
    responseType: "json",
    transformRequest(data) {
      return data;
    },
    transformResponse(data) {
      return data;
    }
  });

  // axios 拦截器，从后向前执行，注意顺序
  // 根据请求类型，格式化参数
  instance.interceptors.request.use((config) => {
    switch (config.method && config.method.toUpperCase()) {
      case "POST":
        config.data = Qs.stringify(config.data);
        break;
      case "GET":
        config.params = config.data;
        break;
    }
    return config;
  });

  // 设置Authorization
  instance.interceptors.request.use(async (config) => {
    const { meta = { auth: false } } = config as IGatewayRequestConfig;
    if (meta.auth) {
      // const token = localStorage.getItem("userToken");
      // if (token) {
      //   config.headers = {
      //     ...config.headers,
      //     Authorization: token
      //   };
      // }
    }
    return config;
  });
  // 设置 "content-type": "application/json"
  instance.interceptors.request.use(async (config) => {
    config.headers = {
      "content-type": "application/json"
    };
    return config;
  });
  // 修补通用参数
  // instance.interceptors.request.use((config) => {
  //   if (!config.data.systemtime) {
  //     config.data.systemtime = Date.now();
  //   }
  //   return config;
  // });
  instance.interceptors.response.use((response) => {
    return response;
  });

  return function <T>(config: IGatewayRequestConfig) {
    return instance.request<any>(R.clone(config));
  };
}
