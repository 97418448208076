import { createApp } from "vue";
import App from "./App.vue";
import { createRouter, createWebHistory } from "vue-router";
import dataV from "@jiaminghi/data-view";
import Home from "./pages/home/index.vue";
import Dignose from "./pages/dignose/index.vue";
import Inbody from "./pages/inbody/index.vue";

const routes = [
  { path: "/", component: Home },
  { path: "/dignose", component: Dignose },
  { path: "/inbody", component: Inbody },
  { path: "/:pathMatch(.*)", redirect: "/" }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

const app = createApp(App);
app.use(router);
app.use(dataV);
app.mount("#app");
