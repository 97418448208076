export function handleSearch(search: any) {
  const result = search.substring(1).split("&");
  const objStore: any = {};

  for (let i = 0; i < result.length; i++) {
    objStore[result[i].split("=")[0]] = result[i].split("=")[1];
  }

  return objStore;
}

// 是否是小程序环境

export function isMiniApp() {
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf("micromessenger") === -1) {
    return false;
  } else {
    //@ts-ignore
    if (window.__wxjs_environment === "miniprogram") {
      return true;
    } else {
      return false;
    }
  }
}

export function fontSize(res) {
  const clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  if (!clientWidth) return;
  const fontSize = 100 * (clientWidth / 23329);
  return res * fontSize;
}

export function loadMetaNoScale() {
  const doc = document;
  const node = doc.createElement("meta");

  node.name = "viewport";
  node.content = "initial-scale=1.0, user-scalable=no, maximum-scale=1, width=device-width, viewport-fit=cover";

  const head = doc.head || doc.getElementsByTagName("head")[0] || doc.documentElement;
  const baseElement = head.getElementsByTagName("base")[0];

  baseElement ? head.insertBefore(node, baseElement) : head.appendChild(node);
}

export function parseUrl(): any {
  const query = location.search.slice(1);
  const searchParams = new URLSearchParams(query);
  const obj: any = {};
  for (const p of searchParams) {
    obj[p[0]] = p[1];
  }
  return obj;
}
