
import {Options, Vue} from "vue-class-component";
import LeftHead from "../components/leftHead/index.vue";
import {datav2H} from "@/api/index";
import {parseUrl} from "@/util/tool";
import screenfull from "screenfull";

@Options({
  components: {
    LeftHead
  }
})
export default class Home extends Vue {
  title: string = "标题Home";
  show: boolean = false;
  loading: boolean = true;
  reset: boolean = false;
  totalPerson: number = 1220;
  groupId: number = 0;
  // 0 中日 中关村
  centerType: number = 0;
  searchWidth: number = 0;
  searchHeight: number = 0;
  screenData: any = {
    configCaple: {
      data: [],
      unit: ""
    }, //慢病患者管理人数
    configService: {
      data: [],
      unit: ""
    }, //慢病患者签约服务人数
    configDevice: {
      data: [],
      unit: ""
    }, //设备使用排行榜
    configConstitute: {
      header: [],
      headerBGC: "",
      data: [[]],
      align: "",
      oddRowBGC: "",
      evenRowBGC: "",
    }, // 实时慢病测量数据
    configPressure: {
      series: [
        {
          type: "",
          data: [
            {
              name: "",
              value: 0
            }
          ],
          radius: ["45%", "65%"],
          insideLabel: {
            show: false
          },
          outsideLabel: {
            labelLineEndLength: 10,
            formatter: "{percent}%\n{name}",
            style: {
              fontSize: 16,
              fill: "#fff"
            }
          }
        }
      ],
      color: [""]
    }, //数据风险分层血压
    configSugar: {
      series: [
        {
          type: "",
          data: [
            {
              name: "",
              value: 0
            }
          ],
          radius: ["45%", "65%"],
          insideLabel: {
            show: false
          },
          outsideLabel: {
            labelLineEndLength: 10,
            formatter: "{percent}%\n{name}",
            style: {
              fontSize: 16,
              fill: "#fff"
            }
          }
        }
      ],
      color: [""]
    }, //数据风险分层血糖
    configBmi: {
      series: [
        {
          type: "",
          data: [
            {
              name: "",
              value: 0
            }
          ],
          radius: ["45%", "65%"],
          insideLabel: {
            show: false
          },
          outsideLabel: {
            labelLineEndLength: 10,
            formatter: "{percent}%\n{name}",
            style: {
              fontSize: 16,
              fill: "#fff"
            }
          }
        }
      ],
      color: [""]
    }, //数据风险分层BMI、身高体重
    configInbody: {
      series: [
        {
          type: "",
          data: [
            {
              name: "",
              value: 0
            }
          ],
          radius: ["45%", "65%"],
          insideLabel: {
            show: false
          },
          outsideLabel: {
            labelLineEndLength: 10,
            formatter: "{percent}%\n{name}",
            style: {
              fontSize: 16,
              fill: "#fff"
            }
          }
        }
      ],
      color: [""]
    }, // 数据风险分层人体成分、Inbody
    configManage: {
      data: [
        {
          name: "",
          value: ""
        }
      ],
      unit: ""
    }, //总筛查人次饼图
    config: {
      data: [
        {
          name: "",
          value: 0
        }
      ],
      showOriginValue: true,
      color: [""]
    },
    dvChartsOption: {
      series: [
        {
          type: "",
          data: [
            {
              name: "",
              value: 0
            }
          ],
          radius: ["45%", "65%"],
          insideLabel: {
            show: false
          },
          outsideLabel: {
            labelLineEndLength: 10,
            formatter: "{percent}%\n{name}",
            style: {
              fontSize: 16,
              fill: "#fff"
            }
          }
        }
      ],
      color: [""]
    } // 地区分布
  };
  // 慢病患者管理人数
  // configCaple = {
  //   data: [
  //     {
  //       name: "18岁以内",
  //       value: 167
  //     },
  //     {
  //       name: "18-39岁",
  //       value: 67
  //     },
  //     {
  //       name: "40-64岁",
  //       value: 123
  //     },
  //     {
  //       name: "65-80岁",
  //       value: 55
  //     },
  //     {
  //       name: "80岁以上",
  //       value: 98
  //     }
  //   ],
  //   unit: "人"
  // };
  // 慢病患者签约服务人数
  // configService = {
  //   total: "xxxxx",
  //   data: [
  //     {
  //       name: "血糖服务包",
  //       value: 167
  //     },
  //     {
  //       name: "血压服务包",
  //       value: 67
  //     },
  //     {
  //       name: "血压血糖服务包",
  //       value: 123
  //     },
  //     {
  //       name: "减重服务包",
  //       value: 55
  //     },
  //     {
  //       name: "呼吸检测服务包",
  //       value: 98
  //     }
  //   ],
  //   unit: "人"
  // };
  // 设备使用排行榜
  // configDevice = {
  //   data: [
  //     {
  //       name: "血糖仪",
  //       value: 167
  //     },
  //     {
  //       name: "血压计",
  //       value: 67
  //     },
  //     {
  //       name: "体脂分析仪",
  //       value: 123
  //     },
  //     {
  //       name: "身高体重仪",
  //       value: 55
  //     }
  //   ],
  //   unit: "次"
  // };
  // 实时慢病测量数据
  // configConstitute = {
  //   header: ["机构名称", "科室", "患者", "年龄", "血压", "血糖", "体重", "身高", "时间"],
  //   headerBGC: "#0086cf",
  //   data: [
  //     ["中日----", "内科", "王**", "28岁", "90/180", "40", "180cm", "65kg", "03-22 12:00"],
  //     ["中日1", "内科", "王**", "2岁", "91/180", "60", "180cm", "65kg", "03-22 12:00"],
  //     ["中日2", "内科", "王**", "18岁", "10/180", "80", "180cm", "65kg", "03-22 12:00"],
  //     ["中日3", "内科", "王**", "8岁", "50/180", "90", "180cm", "65kg", "03-22 12:00"],
  //     ["中日4", "内科", "王**", "36岁", "90/180", "150", "180cm", "65kg", "03-22 12:00"],
  //     ["中日5", "内科", "王**", "7岁", "80/180", "250", "180cm", "65kg", "03-22 12:23"],
  //     ["中日6", "内科", "王**", "8岁", "118/180", "450", "180cm", "65kg", "03-22 12:23"],
  //     ["中日7", "内科", "王**", "9岁", "90/180", "5", "180cm", "65kg", "03-22 12:23"]
  //   ],
  //   align: ["center", "center", "center", "center", "center", "center", "center", "center", "center", "center"],
  //   oddRowBGC: "transparent",
  //   evenRowBGC: "transparent",
  //   columnWidth: [100, 100, 100, 100, 100, 100, 100, 100, 200]
  // };
  // 数据风险分层血压
  // configPressure = {
  //   series: [
  //     {
  //       type: "pie",
  //       data: [
  //         {
  //           name: "高压",
  //           value: 55
  //         },
  //         {
  //           name: "低压",
  //           value: 120
  //         },
  //         {
  //           name: "正常",
  //           value: 120
  //         }
  //       ],
  //       radius: ["45%", "65%"],
  //       insideLabel: {
  //         show: false
  //       },
  //       outsideLabel: {
  //         labelLineEndLength: 10,
  //         formatter: "{percent}%\n{name}",
  //         style: {
  //           fontSize: 16,
  //           fill: "#fff"
  //         }
  //       }
  //     }
  //   ],
  //   color: ["#00baff", "#3de7c9", "#fff", "#ffc530", "#469f4b"]
  // };
  // 数据风险分层血糖
  // configSugar = {
  //   series: [
  //     {
  //       type: "pie",
  //       data: [
  //         {
  //           name: "高",
  //           value: 55
  //         },
  //         {
  //           name: "低",
  //           value: 120
  //         },
  //         {
  //           name: "正常",
  //           value: 120
  //         }
  //       ],
  //       radius: ["45%", "65%"],
  //       insideLabel: {
  //         show: false
  //       },
  //       outsideLabel: {
  //         labelLineEndLength: 10,
  //         formatter: "{percent}%\n{name}",
  //         style: {
  //           fontSize: 16,
  //           fill: "#fff"
  //         }
  //       }
  //     }
  //   ],
  //   color: ["#00baff", "#3de7c9", "#fff", "#ffc530", "#469f4b"]
  // };
  // 数据风险分层BMI、身高体重
  // configBmi = {
  //   series: [
  //     {
  //       type: "pie",
  //       data: [
  //         {
  //           name: "肥胖",
  //           value: 55
  //         },
  //         {
  //           name: "偏瘦",
  //           value: 120
  //         },
  //         {
  //           name: "偏胖",
  //           value: 120
  //         },
  //         {
  //           name: "正常",
  //           value: 120
  //         }
  //       ],
  //       radius: ["45%", "65%"],
  //       insideLabel: {
  //         show: false
  //       },
  //       outsideLabel: {
  //         labelLineEndLength: 10,
  //         formatter: "{percent}%\n{name}",
  //         style: {
  //           fontSize: 16,
  //           fill: "#fff"
  //         }
  //       }
  //     }
  //   ],
  //   color: ["#00baff", "#3de7c9", "#fff", "#ffc530", "#469f4b"]
  // };
  // 数据风险分层人体成分、Inbody
  // configInbody = {
  //   series: [
  //     {
  //       type: "pie",
  //       data: [
  //         {
  //           name: "正常",
  //           value: 55
  //         },
  //         {
  //           name: "异常",
  //           value: 120
  //         }
  //       ],
  //       radius: ["45%", "65%"],
  //       insideLabel: {
  //         show: false
  //       },
  //       outsideLabel: {
  //         labelLineEndLength: 10,
  //         formatter: "{percent}%\n{name}",
  //         style: {
  //           fontSize: 16,
  //           fill: "#fff"
  //         }
  //       }
  //     }
  //   ],
  //   color: ["#00baff", "#3de7c9", "#fff", "#ffc530", "#469f4b"]
  // };
  // 总筛查人次饼图
  // configManage = {
  //   data: [
  //     {
  //       name: "血压",
  //       value: 55
  //     },
  //     {
  //       name: "血糖",
  //       value: 120
  //     },
  //     {
  //       name: "人体成分",
  //       value: 78
  //     },
  //     {
  //       name: "身高体重",
  //       value: 66
  //     }
  //   ]
  // };
  // config = {
  //   data: [
  //     {
  //       name: "北京",
  //       value: 55
  //     },
  //     {
  //       name: "上海",
  //       value: 120
  //     },
  //     {
  //       name: "西安",
  //       value: 78
  //     },
  //     {
  //       name: "深圳",
  //       value: 66
  //     },
  //     {
  //       name: "天津",
  //       value: 80
  //     }
  //   ],
  //   showOriginValue: true,
  //   color: ["red", "yellow", "green", "pink", "#0086cf"]
  // };
  // 地区分布
  // dvChartsOption = {
  //   series: [
  //     {
  //       type: "pie",
  //       data: [
  //         {
  //           name: "北京",
  //           value: 55
  //         },
  //         {
  //           name: "上海",
  //           value: 120
  //         },
  //         {
  //           name: "西安",
  //           value: 78
  //         },
  //         {
  //           name: "深圳",
  //           value: 66
  //         },
  //         {
  //           name: "天津",
  //           value: 80
  //         },
  //         {
  //           name: "其他",
  //           value: 122
  //         }
  //       ],
  //       radius: ["45%", "65%"],
  //       insideLabel: {
  //         show: false
  //       },
  //       outsideLabel: {
  //         labelLineEndLength: 10,
  //         formatter: "{percent}%\n{name}",
  //         style: {
  //           fontSize: 16,
  //           fill: "#fff"
  //         }
  //       }
  //     }
  //   ],
  //   color: ["#00baff", "#3de7c9", "#fff", "#ffc530", "#469f4b"]
  // };
  created() {
    const {groupId, centerType} = parseUrl();
    this.groupId = groupId;
    this.centerType = centerType;
    //
    // width && (this.searchWidth = width);
    // width && (this.searchHeight = height);
    //
    // console.log("searchWidth: " + this.searchWidth);
    //
    // // document.body.style.width = (this.searchWidth || 1920) + "px !important";
    // // document.body.style.height = (this.searchHeight || 1080) + "px !important";
    //
    // document.body.style.background = "yellow";

    this.init();

    setInterval(() => {
      this.init();
    }, 15000);
  }

  beforeCreate() {
    // const {width, height} = parseUrl();
    //
    // width && (this.searchWidth = width);
    // width && (this.searchHeight = height);
    //
    // console.log("searchWidth: " + this.searchWidth);
    //
    // // @ts-ignore
    // document.querySelector("body").setAttribute("style", `width: ${(this.searchWidth || 1920) + "px !important"}`);
    // // @ts-ignore
    // document.querySelector("body").setAttribute("style", `height: ${(this.searchHeight || 1080) + "px !important"}`);
  }

  async init() {
    try {
      let winW = window.screen.width;
      console.log("winW=", winW)
      const result = await datav2H(this.groupId);
      if (result.code === 200) {
        // 动态计算数据轮播的宽度
        let oriArr = result.data.configConstitute.columnWidth;
        if (oriArr) {
          let arr: number[] = [];
          let sum: number = 0;
          let sc = Math.ceil(winW * 0.5);
          for (const w of oriArr) {
            sum = sum + w;
          }
          for (const w of oriArr) {
            arr.push(Math.ceil(sc * w / sum));
          }
          result.data.configConstitute.columnWidth = arr;
        }
        // 适配图表字体大小
        let leftSc = 125;
        let rightSc = 180;
        let centerSc = 80;
        let centerTextSc = 10;
        result.data.dvChartsOption.series[0].outsideLabel.style.fontSize = Math.ceil(winW / leftSc)
        result.data.configPressure.series[0].outsideLabel.style.fontSize = Math.ceil(winW / rightSc)
        result.data.configSugar.series[0].outsideLabel.style.fontSize = Math.ceil(winW / rightSc)
        result.data.configInbody.series[0].outsideLabel.style.fontSize = Math.ceil(winW / rightSc)
        result.data.configBmi.series[0].outsideLabel.style.fontSize = Math.ceil(winW / rightSc)
        result.data.configManage.radius = '40%';
        result.data.configManage.activeRadius = '50%';
        result.data.configManage.lineWidth = Math.ceil(winW / centerSc);


        this.screenData = result.data;
        this.reset = true;
        this.loading = false;
        setTimeout(() => {
          let ringText1: any = document.getElementsByClassName('dv-digital-flop')[0];
          let ringText2: any = document.getElementsByClassName('active-ring-name')[0];
          let headerItemArr: any = document.getElementsByClassName('header-item');
          if (ringText1) {
            ringText1.style.width = Math.ceil(winW / 2.5 / centerTextSc) + 'px'
            ringText1.style.height = Math.ceil(winW / 6 / centerTextSc) + 'px'
          }
          if (ringText2) {
            ringText2.style.width = Math.ceil(winW / centerTextSc) + 'px'
            ringText2.style.height = Math.ceil(winW / 6 / centerTextSc) + 'px'
            ringText2.style.fontSize = Math.ceil(winW / 8 / centerTextSc) + 'px'
          }
          if (headerItemArr) {
            for (let item of headerItemArr) {
              item.style.height = Math.ceil(winW / 5 / centerTextSc) + 'px'
              item.style.paddingTop = 0;
              item.style.fontSize = Math.ceil(winW / 10 / centerTextSc) + 'px'
              item.style.lineHeight = Math.ceil(winW / 5 / centerTextSc) + 'px'
            }
          }
        }, 300);

      }
    } catch (err) {
      console.log(err);
    }
  }

  click() {
    screenfull.toggle();

    setTimeout(() => {
      this.refreshScale();
    });
  }

  refreshScale() {
    let docWidth = document.documentElement.clientWidth;
    let docHeight = document.documentElement.clientHeight;

    console.log("docWidth: " + docWidth);
    console.log("docHeight: " + docHeight);

    var designWidth = this.searchWidth || 1920,
        designHeight = this.searchHeight || 1080,
        widthRatio = docWidth / designWidth,
        heightRatio = docHeight / designHeight;
    console.log("designWidth: " + designWidth);
    console.log("designHeight: " + designHeight);
    // @ts-ignore
    document.body.style.cssText = `width: ${(this.searchWidth || 1920) + "px !important"};height: ${
        (this.searchHeight || 1080) + "px !important"
    };transform:scale(${widthRatio},${heightRatio});transform-origin:left top`;
  }

  mounted() {
    this.$nextTick(() => {
      this.refreshScale();

      setTimeout(() => {
        this.refreshScale();
      }, 0);

      window.addEventListener("resize", this.refreshScale, false);
    });
  }
}
